<template>
  <n-input
    ref="inputRef"
    v-bind="$attrs"
    :value="inputValue"
    @update:value="handleUpdateValue"
    @keydown.enter="handleEnterKeydown"
    @focus="handleFocus"
    @blur="handleBlur"
    v-on.stop="events"
  >
    <template #clear-icon>
      <div class="remove-wrapper flex items-center justify-center">
        <svg-icon name="remove" class="w-4 h-4" />
      </div>
    </template>

    <template v-for="(_, name) in $slots" v-slot:[name]>
      <slot :name="name"></slot>
    </template>
  </n-input>
</template>

<script lang="ts" setup>
import { NInput } from 'naive-ui'

const { isComposing, events } = useCompositionEvent()
const inputRef = ref<Nullable<typeof NInput>>(null)

const inputValue = defineModel<string>()

const emit = defineEmits<{
  (event: 'submit', value: string): true
  (event: 'focus'): true
  (event: 'blur'): true
}>()

const handleUpdateValue = (value: string) => {
  inputValue.value = value
}

const handleEnterKeydown = function () {
  if (!inputValue.value || isComposing.value) return true
  emit('submit', inputValue.value)
}

const handleFocus = () => emit('focus')
const handleBlur = () => emit('blur')

const blur = (...arg: any) => inputRef.value?.blur?.(...arg) || null
const focus = (...arg: any) => inputRef.value?.focus?.(...arg) || false
const scrollTo = (...arg: any) => inputRef.value?.scrollIntoView?.(...arg) || false

defineExpose({
  blur,
  focus,
  scrollTo,
})
</script>

<style lang="scss" scoped></style>
